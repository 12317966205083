<template>
  <div class="container"></div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  created() {
    if (this.$route.query.token) {
      sessionStorage.setItem("token", this.$route.query.token);
      this.getUserInfo(this.$route.query.token);
    }
  },
  methods: {
    ...mapActions(["setUser"]),

    getUserInfo(token) {
      this.$axios
        .get("/api/front/user", {
          token,
        })
        .then((res) => {
          if (res.data.code == 200) {
            console.log(res, "fdsfsdfdsf");
            // 登录信息存到本地缓存
            let user = JSON.stringify(res.data.data);
            // 前端存储用户信息，表示登录成功
            sessionStorage.setItem("user", user);
            // 登录信息存到vuex,控制页面欢迎信息
            // console.log("@@res.data.user", res.data.user)
            this.setUser(res.data.data);
            this.$router.push("/");
          }
        });
    },
  },
};
</script>
